<template>
  <v-row justify="start" class="height100">
    <v-col class="py-0 px-1">
      <SnackBar
        :snackMsg="snackMsg"
        :snackOn="snackOn"
        @close="snackOn = false"
      />

      <v-card
        outlined
        rounded="xl"
        :color="navColor"
        :style="`height: ${rootHeight + 95}px; 
          position: relative;
          width: 100%;
          border: ${borderStyle}`"
      >
        <Header></Header>

        <v-container>
          <ScrollPagination
            v-if="!$route.query.id && !!myProfileId"
            :requestData="{
              'url': `personas`,
              'params': {
                query: this.personaQuery,
                profileId: this.myProfileId
              }
            }"
            :countData="{
              'url': `personas/count`,
            }"
            :rootHeight="`${rootHeight}px`"
            :customPagination="{
              pageSize: 15,
              limit: 15,
            }"
            :forceUpdate="pendingOnBoarding"
            :setLoading="setPersonaLoading"
          >
            <template v-slot:child="props">
              <v-col
                :key="`persona_card_${props.index}`"
                class="py-0"
                cols="12" xs="6" sm="4"
              >
                <PersonaCard :persona="props.item"></PersonaCard>
              </v-col>
            </template>
          </ScrollPagination>

          <v-scroll-y-transition hide-on-leave>
            <Scrollbar
              :flexible="true"
              :thin="true"
              :style="`height: ${rootHeight}px;`"
              class="px-4"
            >
              <PersonaPage
                v-if="$route.query.id && personaId"
                :persona="persona"
                @snackMsg="snackMsg = $event; snackOn = true;"
              ></PersonaPage>
            </Scrollbar>
          </v-scroll-y-transition>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Scrollbar, SnackBar } from '@components/App'
import PersonaPage from '@components/Persona/PersonaPage'
import PersonaCard from '@components/Persona/Card'
import ScrollPagination from '@components/App/ScrollPagination.vue'

import Header from '@components/Persona/Header'

import API from '@api'

export default {
  components: {
    Header,
    ScrollPagination,
    SnackBar,
    Scrollbar,
    PersonaPage,
    PersonaCard
  },

  async created() {
    if (this.$route.query.id) {
      this.persona = await API().get(`persona/${this.user.id}`, {
        params: {
          id: this.$route.query.id
        }
      })

      if (this.persona.id)
        this.personaId = this.persona.id

      if (this.$route.query.activate && this.user) {
        this.SET_PERSONA(this.persona)
        this.SET_ANNA_CHAMBER()
      }
    }
  },

  data: () => ({
    snackMsg: '',
    snackOn: false,
    loading: false,
    personaTag: 'All personas',
    persona: {},
    personaId: '',
    
  }),

  watch: {
    annaChamber(value) {
      if(value) {
        // for smoothing the transition of the Anna Chamber
        this.loading = true 
        setTimeout(() => {
          this.loading = false
        }, 10)
      }
    },
    async $route (to) {
      if (to.query.id) {
        this.personaId = ''

        const persona = await API().get(`persona/${this.user.id}`, {
          params: {
            id: this.$route.query.id
          }
        })

        this.persona = persona        
        if (this.persona.id) this.personaId = this.persona.id
      }     
    }
  },

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      myProfileId: 'profile/id',
      annaChamber: 'annaChamber',
      pendingOnBoarding: 'pendingOnBoarding',
      personaFilter: 'semantic-search/persona',
    }),

    personaQuery() {
      const listQuery = {
        isPublic: {
          $eq: true
        },
        isUnlisted: {
          $eq: false
        },
        'sender.profile': {
          $ne: this.myProfileId
        },
      }

      if (this.personaFilter.value) {
        listQuery.name = {
          $regex: this.personaFilter.value,
          $options: 'i'
        }
      }

      const query = {
        $or: [
          {
            'sender.profile': this.myProfileId
          },
          listQuery
        ],
      }
      return query
    },

    rootHeight() {
      return this.innerHeight * (1 - (100 / this.innerHeight)) - 80
    },

    slicedText() {
      return this.personaTag.length > 15 ? `${this.personaTag.slice(0, 6)}...` : this.personaTag;
    },

    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
    
    navColor() {
      return !this.$vuetify.theme.dark
        ? '#F7F7F7'
        : ''
    }
  },

  methods: {
    ...mapActions({
      SET_PERSONA: 'SET_PERSONA',
    }),
    
    ...mapMutations({
      SET_ANNA_CHAMBER: 'setAnnaChamber',
      setPersonaLoading: 'semantic-search/setPersonaLoading',
    }),
  }
}
</script>