var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"height100",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"py-0 px-1"},[_c('SnackBar',{attrs:{"snackMsg":_vm.snackMsg,"snackOn":_vm.snackOn},on:{"close":function($event){_vm.snackOn = false}}}),_c('v-card',{style:(("height: " + (_vm.rootHeight + 95) + "px; \n        position: relative;\n        width: 100%;\n        border: " + _vm.borderStyle)),attrs:{"outlined":"","rounded":"xl","color":_vm.navColor}},[_c('Header'),_c('v-container',[(!_vm.$route.query.id && !!_vm.myProfileId)?_c('ScrollPagination',{attrs:{"requestData":{
            'url': "personas",
            'params': {
              query: this.personaQuery,
              profileId: this.myProfileId
            }
          },"countData":{
            'url': "personas/count",
          },"rootHeight":(_vm.rootHeight + "px"),"customPagination":{
            pageSize: 15,
            limit: 15,
          },"forceUpdate":_vm.pendingOnBoarding,"setLoading":_vm.setPersonaLoading},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('v-col',{key:("persona_card_" + (props.index)),staticClass:"py-0",attrs:{"cols":"12","xs":"6","sm":"4"}},[_c('PersonaCard',{attrs:{"persona":props.item}})],1)]}}],null,false,3177219166)}):_vm._e(),_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[_c('Scrollbar',{staticClass:"px-4",style:(("height: " + _vm.rootHeight + "px;")),attrs:{"flexible":true,"thin":true}},[(_vm.$route.query.id && _vm.personaId)?_c('PersonaPage',{attrs:{"persona":_vm.persona},on:{"snackMsg":function($event){_vm.snackMsg = $event; _vm.snackOn = true;}}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }